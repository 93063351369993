<template>
    <div class="page page-404 fade-in">
        <img src="/images/icon16.png">
        <h1>
            ERROR 404
        </h1>
        <p>OOPS! We can’t find the page you are looking for</p>
    </div>    
</template>
<script>
export default {
    data(){
        return {
            activeStrategy: 0
        }
    },
    methods: {
        onNext(){
            if( this.activeStrategy == 1 ){
                this.activeStrategy = 0
            } else {
                this.activeStrategy++
            }

        }
    },
 
}
</script>